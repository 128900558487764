<template>
  <div style="padding-bottom: 200px">
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
      v-if="isLoading == false"
    >
      <div class="field col-6 justify-content-start">
        <h2>Task Detail</h2>
        <div v-if="estimate" class="font-light">
          <div class="text-link-hover">
            {{ estimate.ce_number }} - {{ estimate.title }}
          </div>
        </div>
      </div>
      <div
        class="formgrid p-fluid grid justify-content-around align-items-center"
      >
        <Divider class="m-2 mb-5"></Divider>
        <div class="field col-12 sm:col-6">
          <label for="name">Task Type</label>
          <div class="p-inputgroup">
            <Dropdown
              :options="task_types"
              optionLabel="type_name"
              dataKey="uid"
              placeholder="Task Type"
              v-model="task.item"
              @change="handleTaskTypeAndRate(task.item)"
              class="input"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.task.item.required"
          >
            Task Type is required
          </div>
        </div>
        <div class="flex align-items-center col-12 sm:col-6">
          <div class="field col-6">
            <label>Rate / Hour</label>
            <div class="p-inputgroup align-items-center">
              <span class="p-inputgroup-addon">{{
                estimate.client.currency
              }}</span>
              <InputNumber v-model="task.rate" mode="decimal" />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.task.rate.required"
            >
              Rate is required
            </div>
          </div>

          <div class="col-6 p-inputgroup flex align-items-end">
            <AActiveSwitch v-if="!isLoading" v-model="task.billable" />
            <Skeleton
              v-else
              width="4rem"
              height="2rem"
              borderRadius="16px"
            ></Skeleton>
            <span v-if="!isLoading" class="font-light ml-3">Billable</span>
            <Skeleton
              v-else
              class="ml-1 mt-1"
              width="30%"
              borderRadius="6px"
            ></Skeleton>
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label>Task Name</label>
          <div class="p-inputgroup">
            <InputText
              placeHolder="Task Name (optional)"
              v-model="task.title"
            />
          </div>
        </div>
        <div class="flex align-items-center col-12 sm:col-6">
          <div class="field col-6">
            <label>Estimated Time</label>
            <div class="p-inputgroup flex align-items-center">
              <InputMask mask="99:99" v-model="task.estimated_time" />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.task.estimated_time.required"
            >
              Estimate time is required
            </div>
          </div>

          <!-- <div class="field col-6">
            <label>Quantity</label>
            <div class="p-inputgroup">
              <InputNumber
                style="width: 50%"
                class="text-center mr-2"
                mode="decimal"
                :minFractionDigits="2"
                v-model="task.quantity"
                placeholder="Quantity"
              />
            </div>
          </div> -->
        </div>
        <div class="field col-12 p-0">
          <label class="font-light" for="Description">Description</label>
          <Editor
            ref="editor"
            id="Description"
            :autoResize="true"
            editorStyle="height:150px"
            v-model="task.description"
            v-if="!isLoading"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-align"></button>
                <button class="ql-link"></button>
              </span> </template
          ></Editor>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.task.description.required"
          >
            Description is required
          </div>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <div>
          <Button :disabled="!task.rate" type="submit"
            ><template v-if="!selectedObject.item">Add</template
            ><template v-else>Update</template> Task</Button
          >
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>

    <RocketLoad :full="true" v-else />
  </div>
</template>
  <script>
import { fetchTaskTypes, state } from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CostEstimateTaskSlider",
  components: {},
  data() {
    return {
      isLoading: false,
      submitted: false,
      hasTaskRate: false,
      task: {
        id: null,
        active: 1,
        quantity: 0,
        item: null,
        rate: null,
        billable: 1,
        title: null,
        description: null,
        estimated_time: null,
        type: "task_type",
      },
    };
  },
  validations: {
    task: {
      item: {
        required,
      },
      rate: {
        required,
      },
      description: {
        required,
      },
      estimated_time: {
        required,
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    if (this.selectedObject.item) {
      this.task.exists = true;
      this.task = JSON.parse(JSON.stringify(this.selectedObject.item));
      this.task.description = this.task.item.description;
      this.task.billable = this.task.item.billable;
      this.task.rate = parseFloat(this.task.item.price);
      this.task.estimated_time = this.secToHMS(this.task.quantity * 3600);
      this.task.title = this.task.item.title;
      this.task.item = this.task_types.find((a) =>
        this.task.item.type_name.includes(a.type_name)
      );
    }
    this.isLoading = false;
  },

  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async loadData() {
      await fetchTaskTypes(1);
    },
    handleTaskTypeAndRate(type) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasktype/rate/" +
            this.$store.getters.selectedObject.estimate.client.uid +
            "/" +
            type.uid,
          {}
        )
        .then((response) => {
          this.taskTypeRate = response.data.data;
          if (type) {
            let clientBillingTierRate = 0;
            for (let i = 0; i < this.taskTypeRate.length; i++) {
              clientBillingTierRate = this.taskTypeRate[i].price;
            }
            if (clientBillingTierRate > 0) {
              this.hasTaskRate = true;
              this.task.rate = clientBillingTierRate;
            } else {
              this.task.rate = 0;
              this.$toast.add({
                severity: "warn",
                summary:
                  type.type_name +
                  " has no rate specified for this client's billing tier. Please add it under Account Settings > Tasks.",
                life: 5000,
              });
            }
            this.task.billable = type.billable;
            this.task.description = type.description;
          }
        });
    },
    submitHandler() {
      var id = this.uuidv4();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the fields",
          life: 3000,
        });
      } else {
        this.task.id = id;
        var q = this.task.estimated_time.split(":");
        this.task.quantity = parseInt(q[0]) + parseFloat(q[1] / 60);
        this.task.item.description = this.task.description;
        this.task.item.billable = this.task.billable;
        this.task.item.price = this.task.rate;
        var a = this.task.estimated_time.split(":");
        this.task.item.minimum_minutes = +a[0] * 60 + +a[1];
        this.task.item.title = this.task.title;
        if (this.selectedObject.item) {
          this.task.item.uid = this.selectedObject.item.item.uid;
        }
        if (!this.selectedObject.item) {
          delete this.task.item.uid;
          this.group.items.push(this.task);
        } else {
          for (let index = 0; index < this.group.items.length; index++) {
            if (this.group.items[index].uid === this.task.uid) {
              this.$set(this.group.items, index, this.task);
            }
          }
        }
        this.$root.$emit("saveCostEstimate");
        this.clearSliderContent();
      }
    },
  },
  computed: {
    // setCurrency() {
    //   let currency = null;
    //   state.estimateClients.map((client) => {
    //     if (
    //       client.uid == this.$store.getters.selectedObject.estimate.client.uid
    //     ) {
    //       currency = client.currency.name;
    //     }
    //   });
    //   return currency;
    // },
    estimate_clients() {
      return state.estimateClients;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    estimate() {
      return this.selectedObject.estimate;
    },
    group() {
      return this.selectedObject.group;
    },
    task_types() {
      return state.taskTypes;
    },
  },
};
</script>
  <style lang="scss" scoped>
</style>